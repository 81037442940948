<template>
	<div class="product-detail">
		<b-container>
			<h1 class="page-title-simple">Wellvin Produtos</h1>
			<base-btn-sidebar />
			<div class="wrapper">
				<base-sidebar class="d-none d-md-block" />

				<div class="box-detail" v-if="row">
					<b-row>
						<b-col>
							<div class="box-image">
								<!-- <img src="@/assets/images/produtos/temp-prod-detail.jpg" :alt="row.name" /> -->
								<b-img v-if="row.images.length" :src="row.images[0]" fluid :alt="row.name"></b-img>
							</div>
						</b-col>
						<b-col cols="auto">
							<div class="box-info">
								<b-link :to="{ name: 'product.list', query: { cat: row.category.id } }" class="link-cat">
									{{ row.category.name }}
								</b-link>
								<h2>{{ row.name }}</h2>

								<nl2br class="desc" tag="p" :text="row.description" />

								<base-variants :item="row" />
								<div class="box-actions">
									<b-form-input type="number" v-model="row.qty" class="mr-2" />

									<b-btn variant="info" class="btn-add" @click="addToCart(row)" :disabled="disableButton(row)">
										<span>Adicionar </span>
									</b-btn>
								</div>
							</div>
						</b-col>
					</b-row>
				</div>
			</div>
		</b-container>
	</div>
</template>

<script>
import ProductService from '@/services/ProductService'
import BaseBtnSidebar from '@/components/BaseBtnSidebar.vue'
import BaseSidebar from '@/components/BaseSidebar.vue'
import BaseVariants from '@/components/BaseVariants.vue'
export default {
	components: { BaseSidebar, BaseBtnSidebar, BaseVariants },
	data() {
		return {
			row: null,
			category: null
		}
	},
	mounted() {
		this.init()
	},
	methods: {
		async init() {
			const row = await ProductService.getOne(this.$route.params.id)
			row.qty = 1
			this.row = row
		},
		addToCart() {
			this.$store.dispatch('addToCart', this.row)
			this.$bvToast.toast('Produto adicionado com sucesso', {
				title: 'Orçamento',
				solid: true,
				variant: 'info'
			})
		},
		disableButton(row) {
			return Boolean(row.tags.length && !row.variant)
		}
	}
}
</script>

<style lang="scss">
.product-detail {
	.wrapper {
		display: flex;
	}

	.box-image {
		text-align: center;
	}

	.box-detail {
		flex-grow: 1;
	}

	.box-info {
		max-width: 30rem;
	}

	.box-actions {
		margin-top: 2rem;
		display: flex;
	}

	.link-cat {
		display: inline-block;
		margin-bottom: 1rem;
	}

	@include media-breakpoint-down(sm) {
		.box-info {
			margin-top: 4rem;
			text-align: center;
			max-width: 100%;
		}
	}
}
</style>
